import { useStore } from '@/hooks/store/useStore';
import { trpc } from '@/utils/trpc';
import { useMutation } from '@apollo/client';
import Button from '@components/ui/button';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import isEmail from 'validator/lib/isEmail';
import PageBuilder from '../pagebuilder/pageBuilder';
import Input from '../ui/input';
import { SubscribeToNewsletterDocument } from './subscription.gql';

interface Props {
  className?: string;
}

type FormValues = {
  subscription_email: string;
};

const defaultValues = {
  subscription_email: '',
};

export default function Subscription({ className = 'px-5 sm:px-8 md:px-16 2xl:px-24' }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });
  const { storeView } = useStore();
  const { t } = useTranslation(['common']);
  const { data } = trpc.cmsBlocks.get.useQuery({
    identifiers: ['newsletter'],
    storeView,
  });

  function onSubmit(input: FormValues) {
    subscriptionFormMutation({
      variables: {
        email: input.subscription_email,
      },
      errorPolicy: 'ignore',
    });
  }

  const [subscriptionFormMutation, subscriptionFormResult] = useMutation(SubscribeToNewsletterDocument);

  if (!data?.[0]) {
    return <></>;
  }

  return (
    <div
      className={`${className} flex flex-col items-center justify-center bg-gradient-to-r from-gray-150 to-[#dcd1c1] py-10 md:py-14 lg:py-16 xl:flex-row xl:justify-between`}
    >
      <div className="mb-7 text-center md:mb-8 lg:-mt-2 lg:mb-9 xl:-mt-0.5 xl:mb-0 xl:text-start">
        <h3 className="text-lg font-bold text-heading md:text-xl xl:text-3xl">{data[0].title}</h3>
        {data[0].pagebuilder?.components && (
          <div className="mt-4 text-xs leading-6 text-body md:text-sm md:leading-7">
            <PageBuilder
              pagebuilder={data[0].pagebuilder}
              noWrapper={true}
            />
          </div>
        )}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex-shrink-0 sm:w-96 md:w-[545px]"
        noValidate
      >
        <div className="flex flex-col items-center justify-end xl:flex-row xl:items-start">
          {subscriptionFormResult.data ? (
            <div className="flex items-center">
              <IoCheckmarkCircleOutline className="mr-3 text-3xl text-lime-600" />
              {t('text-subscribe-subscribed')}
            </div>
          ) : (
            <>
              <Input
                placeholderKey="forms:placeholder-email-subscribe"
                type="email"
                variant="solid"
                className="w-full"
                inputClassName="px-4 lg:px-7 h-12 lg:h-14 text-center sm:text-start bg-white border-none"
                {...register('subscription_email', {
                  required: 'forms:email-required',
                  validate: (value) => {
                    if (value && !isEmail(value)) {
                      return 'forms:email-error';
                    }
                  },
                })}
                errorKey={errors.subscription_email?.message}
              />
              <Button
                className="mt-3 w-full flex-shrink-0 sm:ms-2 sm:w-auto md:h-full xl:mt-0"
                loading={subscriptionFormResult.loading}
              >
                <span className="lg:py-1">{t('button-subscribe')}</span>
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
